export const isChinaUser = () => {
    if (typeof window !== `undefined`) {
        return window.location.href.indexOf('garmin.cn') !== -1;
    }

    return false;
};

export const isCDCUser = () => {
    if (typeof window !== `undefined`) {
        return window.location.href.indexOf('connect.garmin.cn') !== -1;
    }

    return false;
};
